<template>
  <div v-if="orderById" class="order__right main-block">
    <b-form-select
      v-if="list"
      v-model="employers_id"
      class="add__value main__select mb-3"
      :options="list"
      text-field="name"
      value-field="id"
    />
    <form @submit.prevent="download">
      <button key="dynamic" 
      :style="!employers_id ? `background: ${statusColor('finished')}; color: ${statusColorText('send')}` : ''"
        class="download-btn main-btn mb-3 animated" type="submit">
        Скачать ТТН
      </button>
    </form>
    <form @submit.prevent="downloadDoc">
      <button key="dynamic" 
        class="download-btn main-btn mb-3 animated" type="submit">
        Скачать пропуск
      </button>
    </form>
    <div
      key="main-content1"
      class="main-block_head flex animated"
      :class="{ order__head: btnStatus }"
    >
      <h1 class="order__right-title">Статус заказа</h1>
      <button
        class="order__right-btn main-btn"
        :style="`background: ${statusColor(orderById.status)}; color: ${statusColorText(
          orderById.status
        )}`"
        @click="btnStatus = !btnStatus"
      >
        {{ statusChange(orderById.status) }}
      </button>
    </div>
    <ul key="main-content2" class="order__right-items animated">
      <template v-for="(item, index) in orderPoints">
        <li
          v-if="item"
          :key="item ? item.id : index"
          :ref="'order-item-' + item?.id ?? 'notfound'"
          class="order__right-item flex"
        >
          <div class="order__road road">
            <label
              class="order__road-label"
              :class="{
                'road__label-active': item ? item.status === 'Завершен' : false,
              }"
              for=""
            />
            <div
              v-if="index !== orderById.count_points - 1"
              class="order__road-line"
              :class="{
                'road__line-active': item ? item.status === 'finished' : false,
              }"
            />
          </div>
          <div class="order__right-block flex">
            <div class="order__right-box">
              <div class="order__main-info">
                <h2 class="order__right-name">{{ index + 1 }}. {{ item && item.name }}</h2>
                <p v-if="item && item.employee" class="color-secondary">
                  <small>{{ item.employee.name }}</small>
                </p>
                <p class="order__right-city">
                  {{ item && item.to }}
                </p>
                <p class="order__right-city">
                  {{ item && item.address }}
                </p>
                <div
                  v-if="item && item.comment && item.comment.length > 0"
                  class="order__right-city mt-2"
                >
                  Коммент:
                  <ul>
                    <li
                      v-for="(comment, subIndex) in item.comment"
                      :key="subIndex"
                      class="order__right-comment"
                    >
                      <b-icon class="color" icon="person-fill" />
                      <span>{{ formatDate(comment.date) }} - </span>
                      <span>{{ comment.name }}: </span>
                      <span>{{ comment.reason }}</span>
                    </li>
                  </ul>
                </div>
                <div
                  v-if="item && item.containers && item.containers.length > 0"
                  class="order__right-city order__right-city-container"
                >
                  <span>Контейнеры:</span>
                  <ul class="order__right-containers-list">
                    <li v-for="container in item.containers" :key="container.id">
                      <span v-if="container.status === 2"
                        class="order__right-container-status order__right-container-status--success"
                      />
                      <span v-else
                        :class="{'order__right-container-status--active' : container.status === 1}"
                        class="order__right-container-status"
                      />
                      {{ container.code }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="order__date-wrapper">
                <div v-if="item && item.status !== 'Завершен'" class="order__date">
                  <div style="font-style: italic; font-size: 12px; opacity: 0.6; text-align: right">
                    Прогноз:
                  </div>
                  <h2 class="order__right-date">
                    <template v-if="item.eta">
                      {{ dateProduct(item.eta) }}
                    </template>
                    <span v-else class="order__right-date-processing"> Вычисляется </span>
                  </h2>
                  <p class="order__right-hours">
                    {{ item.eta ? timeProduct(item.eta) : "" }}
                  </p>
                </div>
                <div v-else-if="item && item.status === 'Завершен'" class="order__date">
                  <h2 class="order__right-date">
                    {{ formatDateDay(item.updated_at) || "" }}
                  </h2>
                  <p class="order__right-hours">
                    {{ formatDateTime(item.updated_at) }}
                  </p>
                  <div style="font-style: italic; font-size: 12px; opacity: 0.6; text-align: right">
                    Прогноз:
                  </div>
                  <h2 class="order__right-date">
                    <template v-if="item.eta">
                      {{ dateProduct(item.eta) }}
                    </template>
                    <span v-else class="order__right-date-processing">-</span>
                  </h2>
                  <p class="order__right-hours">
                    {{ item.eta ? timeProduct(item.eta) : "" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import statusMixin from "@/mixins/status";
export default {
  mixins: [statusMixin],
  props: {
    orderById: {
      type: Object,
      default: () => {},
    },
    orderPoints: {
      type: Array,
      default: () => [],
    },
    clickedPointId: {
      type: Number,
      default: null,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      btnStatus: false,
      employers_id: null,
      downloadLink: "",
    };
  },
  computed: {},
  watch: {
    clickedPointId() {
      const clickedPointId = this.clickedPointId;
      this.$refs[`order-item-${clickedPointId}`][0].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
      this.$refs[`order-item-${clickedPointId}`][0].classList.add("selected-point-on-map");
      setTimeout(() => {
        this.$refs[`order-item-${clickedPointId}`][0].classList.remove("selected-point-on-map");
      }, 1000);
    },
  },
  methods: {
    formatDateTime(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleString("ru-RU", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    formatDateDay(dateStr) {
      const months = [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июнь",
        "Июль",
        "Авг",
        "Сен",
        "Окт",
        "Ноя",
        "Дек",
      ];
      const date = new Date(dateStr);
      const monthName = months[date.getMonth()];
      return `${monthName} ${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleString("ru-RU", {
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
      });
    },
    dateProduct(value) {
      if (!value) {
        return;
      }
      const date = value.split(" ")[0].split("-");
      const arrDate = [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июнь",
        "Июль",
        "Авг",
        "Сен",
        "Окт",
        "Ноя",
        "Дек",
      ];
      for (let i = 1; i <= 12; i++) {
        if ((date[1] * 10) / 10 === i) {
          date[1] = arrDate[i - 1];
        }
      }
      return date[1] + " " + date[2] + "/" + date[0].split("")[2] + date[0].split("")[3];
    },
    timeProduct(value) {
      const time = value.split(" ")[1].split(":");
      return time[0] + ":" + time[1];
    },
    download() {
      const query = this.orderById.crossdocking_id
        ? { order_id: this.orderById.crossdocking_id }
        : { order_id: this.$route.params.id };
      this.$api
        .get("/web/order-export", {
          responseType: "blob",
          params: {
            ...query,
            employee_id: this.employers_id,
          },
        })
        .then(async (response) => {
          const responseData = (await response.data.text()) || {};
          const responseJson =
            typeof responseData === "string" ? JSON.parse(responseData) : responseData;
          this.downloadLink = this.$url + "/api/web/ttn/download/" + responseJson.file;
          window.open(this.downloadLink, "_blank");
        })
        .catch((error) => {
          console.error("Произошла ошибка при получении ссылки на скачивание ТТН: ", error);
        });
    },
    downloadDoc() {
      const query = { order_id: this.$route.params.id };
      this.$api
        .get("/web/temp_pass", {
          responseType: "blob",
          params: {
            ...query,
          },
        })
        .then(async (response) => {
          const responseData = (await response.data.text()) || {};
          const responseJson =
            typeof responseData === "string" ? JSON.parse(responseData) : responseData;
          // this.downloadLink = this.$url+'/storage/temp_pass/'+'temp_pass-'+this.$route.params.id+'.pdf';
          this.downloadLink = this.$url + "/api/web/ttn/download/" + responseJson.file;
          window.open(this.downloadLink, "_blank");
        })
        .catch((error) => {
          console.error("Произошла ошибка при получении ссылки на скачивание ТТН: ", error);
        });
    },
  },
};
</script>

<style scoped>
.down-slide-fade-enter,
.down-slide-fade-leave-to {
  transform: translateY(-25%);
  opacity: 0;
}

/* .download-wrapper {
  display: flex;
  flex-wrap: wrap;
}

/* .down-slide-fade-enter,
.down-slide-fade-leave-active {
  opacity: 0;
  transform: translateY(-25%);
}
.down-slide-fade-leave-active {
  position: absolute;
} */
.animated {
  transition: all 0.5s;
}

/* .order__date-wrapper {
  max-width: 70px;
} */
/* .order__main-info {
  flex-grow: 1;
} */
p.color-secondary {
  color: var(--secondary-color);
}
.order__right-date-processing {
  font-style: italic;
  display: inline-block;
  max-width: 3.6em;
  word-wrap: break-word;
  font-weight: 500;
  opacity: 0.6;
  /* margin: 0; */
}
.order__right-comment {
  color: #000000;
  font-size: 1.15em;
  margin-bottom: 0.3rem;
}
.order__right-city-container .order__right-containers-list {
  display: block;
  max-height: 10rem;
  overflow-y: auto;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  box-sizing: content-box;
  white-space: nowrap;
  scrollbar-width: thin;
  position: relative;
  z-index: 10;
}
.order__right-containers-list::-webkit-scrollbar {
  visibility: hidden;
  width: 8px;
}
.order__right-containers-list::-webkit-scrollbar-thumb {
  visibility: visible;
  width: 8px;
  background-color: #bbb7;
  border-radius: 999px;
}
.order__right-containers-list::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.order__right-containers-list::-webkit-scrollbar-thumb:active {
  background-color: #aaa;
}
.order__right-containers-list::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 999px;
}
.order__right-city.order__right-city-container {
  margin-top: 0.25rem;
  font-size: 14px;
  line-height: normal;
}
.order__right-city-container ul {
  padding-left: 0.5rem;
  margin-top: 0.25rem;
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 0.2rem; */
}
/* .order__right-city-container li {
  display: flex;
  align-items: center;
} */
.order__right-container-status {
  display: inline-block;
  width: 0.85rem;
  min-width: 0.85rem;
  height: 0.85rem;
  border-radius: 999rem;
  border: 2px solid var(--secondary-color);
  background-color: #e0e9fa;
  margin-right: 0.3rem;
}
.order__right-container-status--active {
  background-color: var(--secondary-color);
  border-color: #e0e9fa;
}

.order__right-container-status--success {
  background-color: var(--success-color);
  border-color: #e0e9fa;
}

.order__right {
  min-width: 19rem;
  padding: 15px 20px;
}
.order__right-title {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
.order__right-btn {
  height: 30px;
  padding: 0 8px;
  width: auto;
  background: var(--secondary-color);
  border-radius: 5px;
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.order__head {
  border-bottom: 1px solid #f2f2f8;
  padding: 0 0 13px;
  margin-bottom: -10px;
}
.order__right-items {
  margin-top: 15px;
}

.order__right-item {
  min-height: 70px;
  padding: 0 0 10px 0;
  align-items: flex-start;
  position: relative;
}
.order__right-item::before {
  --bg-scale: -0.5rem;
  content: "";
  display: block;
  position: absolute;
  top: var(--bg-scale);
  right: var(--bg-scale);
  bottom: var(--bg-scale);
  left: var(--bg-scale);
  border-radius: 1rem;
  background: transparent;
  transition: all 1s ease-out;
}
.order__right-item.selected-point-on-map::before {
  background: #00bbff1a;
  transition: all 0.2s ease-in;
}
.order__road {
  width: 16px;
  height: 60px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}
.order__road-input {
  visibility: hidden;
  display: none;
}
.download-btn {
  display: block;
  height: 40px;
  padding: 12px 0;
  width: 50%;
}
.download-btn:hover {
  text-decoration: none;
  color: #ffffff;
}
.order__road-label {
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50% 50%;
  background: #e0e9fa;
  border: 2px solid var(--secondary-color);
  position: absolute;
  z-index: 1;
}
.order__road-line {
  width: 2px;
  height: 100%;
  border: 1px dashed #e0e9fa;
  /* border: 1px dashed #e0e9fa; */
  opacity: 1;
  position: absolute;
  top: 11px;
  z-index: 0;
}
.road__line-active {
  background-color: var(--secondary-color);
  width: 4px;
  border: none;
}
.road__label-active {
  background: var(--secondary-color);
  border: 2px solid #e0e9fa;
}
.order__right-block {
  width: 95%;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid #f2f2f8;
}
.order__right-box {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  /* align-items: flex-start; */
}
/* .order__date {
  min-width: 60px;
} */
.order__right-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 0 0 2px 0;
}
.order__right-city {
  font-size: 10px;
  line-height: 12px;
  color: #707070;
}
.order__right-date {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #000000;
  margin: 0 0 2px 0;
}
.order__right-hours {
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #acacac;
}
.order__right-info {
  padding: 8px 0 20px 0;
  font-size: 12px;
  line-height: 14px;
  color: #acacac;
}
</style>
